import React from "react";

// @ts-ignore
import Oee from '../../../static/Logos/oee.svg';

// @ts-ignore
import Mobile from '../../../static/Logos/mobile.svg';

// @ts-ignore
import Smartliv from '../../../static/Logos/smartliv.svg';

// @ts-ignore
import Midtkraft from '../../../static/Logos/midtkraft.svg';

// @ts-ignore
import HurumNett from '../../../static/Logos/hurumnett.svg';

// @ts-ignore
import AskerNett from '../../../static/Logos/askernett.svg';

// @ts-ignore
import Foss from '../../../static/Logos/foss.svg';

interface ILogoCloud {
    bgColor?: string;
    product: string[];
}

const companies = [
    {
        name: "Øvre Eiker Energi",
        image: Oee,
        products: ['kinetiknett', 'generic']
    },
    {
        name: "Foss Fiberoptikk",
        image: Foss,
        products: ['generic']
    },
    {
        name: "Mobile",
        image: Mobile,
        products: ['generic']
    },
    {
        name: "Asker Nett",
        image: AskerNett,
        products: ['kinetiknett', 'generic']
    },
    {
        name: "Smartliv",
        image: Smartliv,
        products: ['generic']
    },
    {
        name: "Midtkraft",
        image: Midtkraft,
        products: ['kinetiknett', 'generic']
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/*

*/
function LogoCloud(props: ILogoCloud) {

    const comps = companies.filter((c) => props.product.some((p) => c.products.includes(p)));

    return (
        <div className={props.bgColor} >
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
                    Noen av våre kunder
                </p>
                <div className="mt-6 flex flex-wrap justify-around">
                    {companies.filter((c) => props.product.some((p) => c.products.includes(p))).map((company) => {
                        return (
                            <div key={company.name}
                                className={classNames(
                                    comps.length === 3 ? 'lg:w-1/3' : 'lg:w-1/6',
                                    "overflow-hidden w-1/2 sm:w-1/2 md:w-1/3  pb-8 px-8 filter grayscale hover:filter-none ")
                                }>
                                <img className="h-8 md:h-10 lg:h-14 mx-auto" src={company.image} alt={company.name} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div >
    );
}

export default LogoCloud;
